import Layout from '../layouts/';
import React from 'react'

const NotFoundPage = () => (
  <Layout data={{
    site: {
    siteMetadata: {
      title: 'Rustam Kasad - Hmm?'
    }
    }
    }}>
  <div>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
    </Layout>
)

export default NotFoundPage
